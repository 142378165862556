import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.sort.js";
export var shuffleArrayImmutably = function shuffleArrayImmutably(array) {
  if (Array.isArray(array)) {
    var arrayCopy = _toConsumableArray(array);

    return arrayCopy.sort(function () {
      return Math.random() - 0.5;
    });
  }

  return null;
};