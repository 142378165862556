import { LinkContainer, ButtonContainer } from 'components/general/button/styles';
import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
export var Actions = styled.div.withConfig({
  displayName: "styles__Actions",
  componentId: "sc-1gtwyp5-0"
})(["display:inline-flex;gap:8px;justify-content:center;width:100%;padding:18px 0 32px;form{display:inline-flex;justify-content:center;flex-wrap:wrap;*{margin:4px;}}a{width:max-content;min-width:140px;}", "{padding:20px 0;}", ":not(:first-child){margin-left:12px;", "{margin-left:0px;}}"], down('sm'), LinkContainer, down('xs'));
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1gtwyp5-1"
})(["display:flex;justify-content:center;flex-direction:column;background-color:", ";width:100%;", "{}"], function (props) {
  return props.color;
}, down('md'));
export var PlaceholderContainer = styled.div.withConfig({
  displayName: "styles__PlaceholderContainer",
  componentId: "sc-1gtwyp5-2"
})(["display:flex;flex-direction:column;align-items:", ";width:100%;", ""], function (props) {
  return props.place === 'hero' ? 'start' : 'center';
}, function (props) {
  return props.place === 'hero' && css(["", "{justify-content:flex-start;}"], Actions);
});
export var TakeActionTitle = styled.div.withConfig({
  displayName: "styles__TakeActionTitle",
  componentId: "sc-1gtwyp5-3"
})(["margin:0 auto;color:#9b9fa4;line-height:125%;font-size:16px;"]);