import "core-js/modules/es.string.link.js";
import styled, { css } from 'styled-components';
import { darken } from 'polished';
var fullProperty = css(["width:100%;max-width:100%;"]);
var basicProperties = css(["position:relative;display:flex;justify-content:center;align-items:center;font-family:", ";font-weight:bold;font-size:", ";width:max-content;height:", ";min-height:", ";padding:0 16px;cursor:pointer;transition:all 0.3s ease;border:0;border-radius:", ";"], function (props) {
  return props.theme.buttons.font.family;
}, function (props) {
  return props.theme.buttons.font.size;
}, function (props) {
  return props.theme.buttons.height.regular;
}, function (props) {
  return props.theme.buttons.height.regular;
}, function (props) {
  return props.squared ? 0 : '16px';
});
var buttonColors = {
  primary: css(["color:white;background:", ";box-shadow:0px 10px 20px rgba(0,0,0,0.1);border-radius:50px;&:hover,&:active,&:focus{background:linear-gradient(to right bottom,#59ba3f 0%,#4da82a 33.33%,#3d7d27 100%);color:white;}"], function (props) {
    return props.theme.colors.gradients.green;
  }),
  secondary: css(["color:#14142b;background-color:transparent;border:1px solid #58c12c;border-radius:50px;color:#58c12c;&:hover,&:active,&:focus{background-color:", ";color:#14142b;}"], darken(0.1, '#EDEDEF')),
  tertiary: css(["color:#14142b;background-color:#ff617b;&:hover,&:active,&:focus{background-color:", ";color:#14142b;}"], darken(0.1, '#FF617B')),
  success: css(["color:#ffffff;background-color:'#00966D';&:hover,&:active,&:focus{background-color:", ";color:#ffffff;}"], darken(0.1, '#00966D')),
  warning: css(["color:#ffffff;background-color:'#FF7F11';&:hover,&:active,&:focus{background-color:", ";color:#ffffff;}"], darken(0.1, '#FF7F11')),
  danger: css(["color:#ffffff;background-color:#c30052;&:hover,&:active,&:focus{background-color:", ";color:#ffffff;}"], darken(0.1, '#C30052'))
};
var linkColors = {
  primary: css(["background-color:transparent;color:white;&:hover{color:'#58c12c';}"]),
  secondary: css(["background-color:transparent;color:'#6E7191';&:hover{background-color:#ededef;color:'#6E7191';}"]),
  tertiary: css(["background-color:transparent;color:'#14142B';&:hover{background-color:#ededef;color:'#14142B';}"]),
  success: css(["background-color:transparent;color:'#00966D';&:hover{background-color:#ededef;color:'#00966D';}"]),
  warning: css(["background-color:transparent;color:'#FF7F11';&:hover{background-color:#ededef;color:'#FF7F11';}"]),
  danger: css(["background-color:transparent;color:'#C30052';&:hover{background-color:#ededef;color:'#C30052';}"])
};
export var ButtonContainer = styled.button.withConfig({
  displayName: "styles__ButtonContainer",
  componentId: "sc-139b2al-0"
})(["", ";", ";", ";", ";", ";", " ", ";"], basicProperties, function (props) {
  return props.link ? linkColors[props.color] : buttonColors[props.color];
}, function (props) {
  return props.padding && "padding: ".concat(props.padding);
}, function (props) {
  return props.margin && "margin: ".concat(props.margin);
}, function (props) {
  return props.width && "width: ".concat(props.width);
}, function (props) {
  return props.disabled && "opacity: 50%; pointer-events:none;";
}, function (props) {
  return props.full && fullProperty;
});
export var LinkContainer = styled.a.withConfig({
  displayName: "styles__LinkContainer",
  componentId: "sc-139b2al-1"
})(["", ";", ";", ";", ";", ";", " ", ";"], basicProperties, function (props) {
  return props.link ? linkColors[props.color] : buttonColors[props.color];
}, function (props) {
  return props.padding && "padding: ".concat(props.padding);
}, function (props) {
  return props.margin && "margin: ".concat(props.margin);
}, function (props) {
  return props.width && "width: ".concat(props.width);
}, function (props) {
  return props.disabled && "opacity: 50%; pointer-events:none;";
}, function (props) {
  return props.full && fullProperty;
});