import { ButtonContainer } from 'components/general/button/styles';
import { WrapperContainer } from 'partials/common/contentWrapper/styles';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Actions } from '../styles';
export var Title = styled.h2.withConfig({
  displayName: "styles__Title",
  componentId: "sc-qpukuy-0"
})(["display:flex;justify-content:center;margin:40px 0;max-width:60%;text-align:center;font-size:16px;line-height:125%;", "{margin:20px 0;}", "{max-width:100%;}"], down('md'), down('xs'));
export var Subject = styled.span.withConfig({
  displayName: "styles__Subject",
  componentId: "sc-qpukuy-1"
})(["display:flex;justify-content:center;padding-left:0px;text-transform:uppercase;font-size:14px;font-weight:600;line-height:100%;letter-spacing:0.25px;color:#9b9fa4;"]);
export var Subtitle = styled.p.withConfig({
  displayName: "styles__Subtitle",
  componentId: "sc-qpukuy-2"
})(["display:flex;text-align:left;color:transparentize(0.25,#ffffff);"]);
export var DefaultContainer = styled.div.withConfig({
  displayName: "styles__DefaultContainer",
  componentId: "sc-qpukuy-3"
})(["display:flex;width:100%;padding:60px 0;", "{padding:32px 0;}", "{align-items:center;}", "{min-width:100px;}", "{padding-bottom:0px;}"], down('md'), WrapperContainer, ButtonContainer, Actions);
export var HeroContainer = styled.div.withConfig({
  displayName: "styles__HeroContainer",
  componentId: "sc-qpukuy-4"
})(["display:flex;flex-direction:column;justify-self:flex-start;justify-content:flex-start;width:70%;", "{width:100%;}", "{min-width:100px;", "{min-width:80px;}}", "{justify-content:flex-start;margin-bottom:24px;text-transform:none;font-size:26px;font-weight:700;max-width:500px;color:", ";", "{margin-bottom:8px;font-size:16px;}}", "{text-align:left;margin:0;font-size:56px;font-weight:700;line-height:110%;color:#ffffff;", "{font-size:33px;}}form{justify-content:flex-start;}"], down('md'), ButtonContainer, down('md'), Subject, function (props) {
  return props.theme.colors.undp.green;
}, down('md'), Title, down('md'));
export var SurveyEndContainer = styled.div.withConfig({
  displayName: "styles__SurveyEndContainer",
  componentId: "sc-qpukuy-5"
})(["display:flex;flex-direction:column;width:100%;", "{padding:0 0 16px;}", "{font-size:16px;text-transform:none;color:#9b9fa4;}", "{max-width:100%;font-size:16px;line-height:120%;color:#11151a;margin:16px 0;}", "{min-width:100px;}"], down('md'), Subject, Title, ButtonContainer);