import { down } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
var DefaultLoadingStyle = css(["padding:60px 0;", "{padding:32px 0;.ant-skeleton-content{margin-bottom:16px;}}.ant-skeleton-content{margin-bottom:32px;", "{margin-bottom:0;}}.ant-skeleton-content,.ant-space{display:flex;justify-content:center;}.ant-skeleton-button{margin-bottom:0;margin-top:20px;}"], down('md'), down('sm'));
var HeroLoadingStyle = css(["max-width:60%;", "{max-width:100%;}.ant-space{display:flex;justify-content:flex-start;margin-top:16px;}"], down('sm'));
var SurveyEndLoadingStyle = css(["padding:0;", "{padding:32px 0;}.ant-skeleton-content,.ant-space{display:flex;justify-content:center;}.ant-skeleton-button{margin-bottom:0;margin-top:20px;}"], down('md'));
var placeStyles = {
  hero: HeroLoadingStyle,
  surveyEnd: SurveyEndLoadingStyle,
  default: DefaultLoadingStyle
};
export var LoadingContainer = styled.div.withConfig({
  displayName: "styles__LoadingContainer",
  componentId: "sc-1e8l2jg-0"
})(["display:flex;flex-direction:column;width:100%;", ""], function (containerProps) {
  return placeStyles[containerProps.place];
});